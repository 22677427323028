import css from 'styled-jsx/css';

export default css.global`
html {
  font-size: 16px !important;
}
@media only screen and (min-width: 1499px) and (max-width: 1539px) and (min-resolution: 1.16dppx) and (max-resolution: 1.35dppx) {
  html {
    font-size: 14.8px !important;
  }
}
@media only screen and (min-width: 1370px) and (max-width: 1499px) and (min-resolution: 0.9dppx) and (max-resolution: 1.15dppx) {
  html {
    font-size: 16px !important;
  }
}
@media only screen and (min-width: 1370px) and (max-width: 1499px) and (min-resolution: 1.16dppx) and (max-resolution: 1.35dppx) {
  html {
    font-size: 13.5px !important;
  }
}
@media only screen and (min-width: 1370px) and (max-width: 1499px) and (min-resolution: 1.36dppx) and (max-resolution: 1.65dppx) {
  html {
    font-size: 12.5px !important;
  }
}
@media only screen and (max-width: 1369px) and (orientation: landscape) {
  html {
    font-size: 13.5px !important;
  }
}

@media only screen and (min-resolution: 1.8dppx) and (max-resolution: 2.8dppx) {
  html {
    font-size: 15.7px !important;
  }
}
@media only screen and (min-resolution: 2.8dppx) and (max-resolution: 3dppx) {
  html {
    font-size: 15.5px !important;
  }
}
@media only screen and (min-resolution: 3dppx) and (max-resolution: 3.1dppx) {
  html {
    font-size: 14.7px !important;
  }
}
@media only screen and (min-resolution: 3.1dppx) and (max-resolution: 3.2dppx) {
  html {
    font-size: 13.9px !important;
  }
}
@media only screen and (min-resolution: 3.2dppx) and (max-resolution: 3.5dppx) {
  html {
    font-size: 13.1px !important;
  }
}
`;
